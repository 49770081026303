import React from 'react';
import {Button, message} from 'antd';
import {BlobProvider} from '@react-pdf/renderer';
import * as ShippingNoteUtil from '../../../Utils/ShippingNoteUtil';
import * as AppActions from '../../../AppActions';
import AdminShippingNotePdf from '../AdminShippingNotePdf';

export default function ShippingNoteDownloadPdfButton(props) {
  const {order} = props;
  const [generatedOrders, setGeneratedOrders] = React.useState([]);

  return (
    <>
      <Button
        className="shipping-note-download-btn"
        type="primary"
        ghost
        onClick={async () => {
          if (
            order.logistics_subtype === 'SF' ||
            order.logistics_type === 'OVERSEA'
          ) {
            alert(`注意：本訂單的物流方式不支援此操作。`);
            return;
          }

          AppActions.setLoading(true);

          try {
            const results = [];
            const {data} = await ShippingNoteUtil.prepareShippingNote(order);
            results.push({order, data});
            setGeneratedOrders(results);
          } catch (ex) {
            console.warn(ex);
          }

          AppActions.setLoading(false);
        }}>
        下載托運單
      </Button>

      {generatedOrders.length > 0 && (
        <BlobProvider
          document={<AdminShippingNotePdf generatedOrders={generatedOrders} />}>
          {({...rest}) => {
            if (rest.url) {
              return (
                <a
                  href="/#"
                  style={{margin: 10}}
                  onClick={(evt) => {
                    evt.preventDefault();
                    window.open(rest.url, '_blank');
                  }}>
                  下載PDF
                </a>
              );
            } else {
              return (
                <a href="/#" style={{margin: 10}}>
                  Generating...
                </a>
              );
            }
          }}
        </BlobProvider>
      )}
    </>
  );
}
