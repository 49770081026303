import React from 'react';
import * as Ant from 'antd';
import * as Jstorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import AdminLinkButton from './AdminLinkButton';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import * as CatUtil from '../../Utils/CatUtil';

export function handleExtra({path, pageContext}) {
  /* 
    if you want to custom behavier for admin resource  
    EXAMPLE: 
    if (path.indexOf('xxx') > -1) {
      pageContext.resource.xxx = xxx;
    }
  */

  // reference by AppAction.getDefaultCheckoutFormSpec setting
  if (
    path.indexOf('/admin/projects') > -1 &&
    path.indexOf('admin/projects-') === -1
  ) {
    let properties = pageContext.resource.formSpec.schema.properties;

    const paymentSubTypes = Object.values(Cart.PAYMENT_SUBTYPE);
    properties.paymentSubTypes.items.enum = paymentSubTypes;
    properties.paymentSubTypes.items.enumNames = paymentSubTypes.map(
      (key) => Cart.PAYMENT_SUBTYPE_DISPLAY[key].label + ` (${key})`,
    );

    const logisticsTypes = Object.values(Cart.LOGISTICS_TYPE);
    properties.logisticsTypes.items.enum = logisticsTypes;
    properties.logisticsTypes.items.enumNames = logisticsTypes.map(
      (key) => Cart.LOGISTICS_TYPE_DISPLAY[key].label,
    );

    const logisticsSubTypes = {
      type: 'object',
      properties: {
        [Cart.LOGISTICS_TYPE.cvs]: {
          title: Cart.LOGISTICS_TYPE_DISPLAY[Cart.LOGISTICS_TYPE.cvs].label,
          type: 'array',
          default: [],
          uniqueItems: true,
          items: {
            enum: [
              Cart.LOGISTICS_SUBTYPE.famic2c,
              Cart.LOGISTICS_SUBTYPE.hilifec2c,
              Cart.LOGISTICS_SUBTYPE.unimartc2c,
              Cart.LOGISTICS_SUBTYPE.fami, // B2C
              Cart.LOGISTICS_SUBTYPE.unimart, // B2C
              Cart.LOGISTICS_SUBTYPE.hilife, // B2C
            ],
            enumNames: [
              Cart.LOGISTICS_SUBTYPE_DISPLAY[Cart.LOGISTICS_SUBTYPE.famic2c]
                .label + 'C2C',
              Cart.LOGISTICS_SUBTYPE_DISPLAY[Cart.LOGISTICS_SUBTYPE.hilifec2c]
                .label + 'C2C',
              Cart.LOGISTICS_SUBTYPE_DISPLAY[Cart.LOGISTICS_SUBTYPE.unimartc2c]
                .label + 'C2C',
              Cart.LOGISTICS_SUBTYPE_DISPLAY[Cart.LOGISTICS_SUBTYPE.fami]
                .label + 'B2C',
              Cart.LOGISTICS_SUBTYPE_DISPLAY[Cart.LOGISTICS_SUBTYPE.unimart]
                .label + 'B2C',
              Cart.LOGISTICS_SUBTYPE_DISPLAY[Cart.LOGISTICS_SUBTYPE.hilife]
                .label + 'B2C',
            ],
          },
        },
        [Cart.LOGISTICS_TYPE.home]: {
          title: Cart.LOGISTICS_TYPE_DISPLAY[Cart.LOGISTICS_TYPE.home].label,
          type: 'array',
          default: [],
          uniqueItems: true,
          items: {
            enum: [
              Cart.LOGISTICS_SUBTYPE.SF,
              Cart.LOGISTICS_SUBTYPE.TCAT,
              Cart.LOGISTICS_SUBTYPE.ECAN,
            ],
            enumNames: [
              Cart.LOGISTICS_SUBTYPE_DISPLAY[Cart.LOGISTICS_SUBTYPE.SF].label,
              Cart.LOGISTICS_SUBTYPE_DISPLAY[Cart.LOGISTICS_SUBTYPE.TCAT].label,
              Cart.LOGISTICS_SUBTYPE_DISPLAY[Cart.LOGISTICS_SUBTYPE.ECAN].label,
            ],
          },
        },
        [Cart.LOGISTICS_TYPE.oversea]: {
          title: Cart.LOGISTICS_TYPE_DISPLAY[Cart.LOGISTICS_TYPE.oversea].label,
          type: 'array',
          default: [],
          uniqueItems: true,
          items: {
            enum: [
              Cart.LOGISTICS_SUBTYPE.SF_OVERSEA_CN,
              Cart.LOGISTICS_SUBTYPE.SF_OVERSEA_HK,
              Cart.LOGISTICS_SUBTYPE.SF_OVERSEA_MO,
            ],
            enumNames: [
              Cart.LOGISTICS_SUBTYPE_DISPLAY[
                Cart.LOGISTICS_SUBTYPE.SF_OVERSEA_CN
              ].label,
              Cart.LOGISTICS_SUBTYPE_DISPLAY[
                Cart.LOGISTICS_SUBTYPE.SF_OVERSEA_HK
              ].label,
              Cart.LOGISTICS_SUBTYPE_DISPLAY[
                Cart.LOGISTICS_SUBTYPE.SF_OVERSEA_MO
              ].label,
            ],
          },
        },
        [Cart.LOGISTICS_TYPE.self_pick]: {
          title:
            Cart.LOGISTICS_TYPE_DISPLAY[Cart.LOGISTICS_TYPE.self_pick].label,
          type: 'array',
          default: [],
          items: {
            type: 'string',
          },
        },
        [Cart.LOGISTICS_TYPE.custom]: {
          title:
            Cart.LOGISTICS_TYPE_DISPLAY[Cart.LOGISTICS_TYPE.self_pick].label,
          type: 'array',
          default: [],
          items: {
            type: 'string',
          },
        },
      },
    };
    properties.logisticsSubTypes = logisticsSubTypes;

    const invoiceCategories = Object.values(Cart.INVOICE_CATEGORY);
    properties.invoiceCategories.items.enum = invoiceCategories;
    properties.invoiceCategories.items.enumNames = invoiceCategories.map(
      (key) => Cart.INVOICE_CATEGORY_DISPLAY[key].label,
    );

    const invoiceCarrierTypes = Object.values(Cart.INVOICE_CARRIER_TYPE);
    properties.invoiceCarrierTypes.items.enum = invoiceCarrierTypes;
    properties.invoiceCarrierTypes.items.enumNames = invoiceCarrierTypes.map(
      (key) => Cart.INVOICE_CARRIER_TYPE_DISPLAY[key].label,
    );

    const invoiceTypes = Object.values(Cart.INVOICE_TYPE);
    properties.invoiceTypes.items.enum = invoiceTypes;
    properties.invoiceTypes.items.enumNames = invoiceTypes.map(
      (key) => Cart.INVOICE_TYPE_DISPLAY[key],
    );

    // remove image uploader
    delete pageContext.resource.formSpec.uiSchema['faviconImage']['ui:widget'];
    delete pageContext.resource.formSpec.uiSchema['logoImage']['ui:widget'];
    delete pageContext.resource.formSpec.uiSchema['spinnerImage']['ui:widget'];
    delete pageContext.resource.formSpec.uiSchema['emptyImage']['ui:widget'];
    delete pageContext.resource.formSpec.uiSchema['loginRequiredImage'][
      'ui:widget'
    ];
  }
}
