import React from 'react';
import styled from 'styled-components';

export default function AdminDateTimeWidget(props) {
  const {value, onChange} = props;

  let _value = value ? new Date(value) : new Date();
  _value = _value.toLocaleString('sv').replace(' ', 'T').slice(0, 16);
  // input value must be YYYY-MM-DDTHH:mm

  return (
    <Wrapper>
      <input
        type="datetime-local"
        value={_value}
        onChange={(e) => {
          const value = e.target.value;

          if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/.test(value)) {
            const date = new Date(value);
            onChange(date.getTime()); // transform to timestamp
          }
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > input {
    border: 1px solid #d9d9d9;
    height: 30px;
    padding: 0px 11px;

    &:hover {
      border: solid 1px var(--primaryColor);
    }

    &:focus {
      border: solid 1px var(--primaryColor);
      outline: none;
    }
  }
`;
