import React from 'react';
import MktGetProjectEditLinkButton from '../../Generators/AdminResource/MktGetProjectEditLinkButton';

export function renderCustomAdminSection(props) {
  const {name, type, context} = props;
  return [false, null];
}

export function renderCustomAdminCol(props) {
  const {col, record} = props;

  if (col.customType === 'customer-access-link') {
    return [true, <MktGetProjectEditLinkButton record={record} />];
  }

  return [false, null];
}
