import React from 'react';
import * as Ant from 'antd';
import {getOutlet, useOutlet} from 'reconnect.js';
import AdminResource from 'rev.sdk.js/Generators/AdminResource';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as OrderUtil from 'rev.sdk.js/Utils/OrderUtil';
import * as CustomAdminResource from '../../Utils/CustomAdminResource';
import useAdminResourceInit from '../../Hooks/useAdminResourceInit';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import * as AdminOrderWidget from './AdminOrderWidget';
import * as AdminStockWidget from './AdminStockWidget';
import AdminSelectUserWidget from './AdminSelectUserWidget';
import AdminSelectProductWidget from './AdminSelectProductWidget';
import AdminSelectArticleWidget from './AdminSelectArticleWidget';
import AdminDateTimeWidget from './AdminDateTimeWidget';
import AdminOrderDetailForm from './AdminOrderDetailForm';
import AdminProductShareButton from './AdminProductShareButton';
import AdminProductStockSummary from './AdminProductStockSummary';
import AdminPrivateProfile from './AdminPrivateProfile';
import AdminLinkButton from './AdminLinkButton';
import AdminSiteConfigButton from './AdminSiteConfigButton';
import AdminArticleBelowSection from './AdminArticleBelowSection';
import AdminPageBuilderButtonAction from './AdminPageBuilderBottonAction';
import {handleProducts} from './handleProducts';
import {handleArticles} from './handleArticles';
import {handleOrders} from './handleOrders';
import {handleOrdersBulkWrite} from './handleOrdersBulkWrite';
import {handleStocks} from './handleStocks';
import {handleConfigs} from './handleConfigs';
import {handleRebuildHistory} from './handleRebuildHistory';
import {handleExtra} from './handleExtra';
import {cloneDeep} from 'lodash';

function AdminResourcePage(props) {
  const {location, path} = props;
  const pageContext = cloneDeep(props.pageContext);
  useAdminResourceInit({location});

  function renderCustomAdminSection(props) {
    const {name, type, context} = props;
    const [
      isOverride,
      overrideResp,
    ] = CustomAdminResource.renderCustomAdminSection({...props, path});

    if (isOverride) {
      return overrideResp;
    }

    if (
      type === 'form' &&
      name === 'AdminOrderDetailForm' &&
      context.position === 'bottom'
    ) {
      return <AdminOrderDetailForm context={context} />;
    } else if (type === 'form' && name === 'ArticleForm') {
      if (context.position === 'top') {
        return <h2 style={{margin: '15px 0'}}>基本資料</h2>;
      }

      if (context.position === 'below-form') {
        return (
          <AdminArticleBelowSection
            context={context}
            name={name}
            type={type}
            path={path}
            multilingual={path === '/admin/i18n-articles'}
          />
        );
      }
    } else if (
      type === 'form' &&
      name === 'ProductForm' &&
      context.position === 'bottom'
    ) {
      return <AdminProductShareButton context={context} />;
    } else if (name === 'UserCustom' && context.position === 'top') {
      return <AdminPrivateProfile context={context} />;
    } else if (
      type === 'resource' &&
      path.indexOf('/admin/orders/') !== -1 &&
      context.position === 'middle'
    ) {
      return <AdminOrderWidget.OrderExtraQueries {...context} />;
    } else if (
      type === 'form' &&
      path.indexOf('/admin/pages') !== -1 &&
      context.position === 'below-form'
    ) {
      return <AdminPageBuilderButtonAction location={location} {...props} />;
    }
    return null;
  }

  function renderCustomAdminCol(props) {
    const {col, record} = props;
    const [isOverride, overrideResp] = CustomAdminResource.renderCustomAdminCol(
      props,
    );

    if (isOverride) {
      return overrideResp;
    }

    if (col.customType === 'labels') {
      return record.labels?.map((l, idx) => <Ant.Tag key={idx}>{l}</Ant.Tag>);
    } else if (col.customType === 'label') {
      return record.label?.map((l, idx) => <Ant.Tag key={idx}>{l}</Ant.Tag>);
    } else if (col.customType === 'site-config-name') {
      return <AdminSiteConfigButton record={record} />;
    } else if (col.customType === 'order_status') {
      return <AdminOrderWidget.OrderStatusCustomElem record={record} />;
    } else if (col.customType === 'order_ui_status') {
      return <AdminOrderWidget.OrderUiStatusCustomElem record={record} />;
    } else if (col.customType === 'payment_status') {
      return <AdminOrderWidget.PaymentStatusCustomElem record={record} />;
    } else if (col.customType === 'payment_subtype') {
      return <AdminOrderWidget.PaymentSubtypeCustomElem record={record} />;
    } else if (col.customType === 'logistics_status') {
      return <AdminOrderWidget.LogisticsStatusCustomElem record={record} />;
    } else if (col.customType === 'logistics_type') {
      return <AdminOrderWidget.LogisticsTypeCustomElem record={record} />;
    } else if (col.customType === 'offline_tx') {
      return <AdminOrderWidget.OfflineTxCustomElem record={record} />;
    } else if (col.customType === 'stock_type') {
      return <AdminProductStockSummary record={record} />;
    } else if (col.customType === 'stock_amount') {
      if (!record.stock_type || record.stock_type === 'always') {
        return '-- --';
      }
      return record.stock_amount;
    } else if (col.customType === 'stock_sold_amount') {
      if (!record.stock_type || record.stock_type === 'always') {
        return '-- --';
      }
      return record.stock_sold_amount;
    } else if (col.customType === 'stock_value') {
      return <AdminStockWidget.StockValueCustomElem value={record.value} />;
    } else if (col.customType === 'stock_remaining') {
      return record.to - record.stock_sold_amount;
    } else if (col.customType === 'return_id') {
      return (
        <AdminLinkButton
          title={record.id}
          url={`/admin/orders/return/?action=detail&id=${record.id}`}
        />
      );
    } else if (col.customType === 'return_order') {
      return (
        <AdminLinkButton
          title={record.order_id || '訂單詳情'}
          url={`/admin/order/?action=detail&id=${record.order}`}
        />
      );
    } else if (col.customType === 'return_reason') {
      return record.data?.reason;
    } else if (col.customType === 'return_status') {
      return <AdminOrderWidget.ReturnStatusCustomElem record={record} />;
    } else if (col.customType === 'coupon_type') {
      return (
        <AdminLinkButton
          title={record.type === 'global' ? '全站' : '個人'}
          url={`/admin/coupons/?action=detail&id=${record.id}`}
        />
      );
    } else if (col.customType === 'page-preview') {
      return (
        <a
          href={`/page-preview/?id=${record.id}`}
          target="_blank"
          rel="noreferrer">
          立即查看
        </a>
      );
    } else if (col.customType === 'page-copy') {
      return (
        <Ant.Button
          onClick={async () => {
            AppActions.setLoading(true);
            try {
              let recordCopy = {...record};
              delete recordCopy.id;
              delete recordCopy._id;
              recordCopy = {
                ...recordCopy,
                name: `${recordCopy.name}__copy`,
                path: '',
              };
              Ant.message.info('頁面創建中...');
              await JStorage.createDocument('page', recordCopy);
              window.location.reload();
            } catch (ex) {
              console.warn(ex);
              Ant.message.error('API fail, 請稍後再試');
            }
            AppActions.setLoading(false);
          }}>
          拷貝
        </Ant.Button>
      );
    } else if (col.customType === 'page-delete') {
      return (
        <Ant.Button
          onClick={async () => {
            AppActions.setLoading(true);
            try {
              if (
                window.confirm(
                  `確定要刪除頁面 ${record.name} 嗎?\n本動作無法復原`,
                )
              ) {
                Ant.message.info('頁面刪除中...');
                await JStorage.deleteDocument('page', {id: record.id});
                window.location.reload();
              }
            } catch (ex) {
              console.warn(ex);
              Ant.message.error('API fail, 請稍後再試');
            }
            AppActions.setLoading(false);
          }}>
          刪除
        </Ant.Button>
      );
    } else if (col.customType === 'survey_link') {
      return (
        <AdminLinkButton title={'問卷連結'} url={`/survey/?id=${record.id}`} />
      );
    } else if (col.customType === 'survey_stats') {
      return (
        <AdminLinkButton
          title={'統計結果'}
          url={`/survey/result/?id=${record.id}`}
        />
      );
    } else if (col.customType === 'survey_resp_values') {
      return (
        <div>
          {(record.values || []).map((value, idx) => {
            if (typeof value === 'string') {
              return <div key={idx}>{`${idx + 1} / ${value}`}</div>;
            } else if (typeof value === 'boolean') {
              return (
                <div key={idx}>{`${idx + 1} / ${value ? 'Yes' : 'No'}`}</div>
              );
            } else if (typeof value === 'object') {
              return (
                <div key={idx}>{`${idx + 1} / ${Object.keys(value)
                  .map((v) => parseInt(v, 10) + 1)
                  .join(',')}`}</div>
              );
            }
            return <div key={idx}>{`${idx + 1} / ---`}</div>;
          })}
        </div>
      );
    } else if (col.customType === 'survey_resp_owner') {
      if (!record.owner) {
        return '---';
      }

      return (
        <Ant.Button
          type="link"
          onClick={async () => {
            const upr = await JStorage.fetchOneDocument('user_profile', {
              owner: record.owner,
            });

            window.open(`/admin/users/?action=detail&id=${upr.id}`);
          }}>
          查看答題者
        </Ant.Button>
      );
    } else if (col.customType === 'survey_resp_go_to_survey') {
      return (
        <AdminLinkButton
          title={'查看問卷'}
          url={`/survey/result/?id=${record.survey}`}
        />
      );
    }

    return null;
  }

  handleProducts({path, pageContext});
  handleArticles({path, pageContext});
  handleOrders({path, pageContext});
  handleOrdersBulkWrite({path, pageContext});
  handleStocks({path, pageContext});
  handleConfigs({path, pageContext});
  handleRebuildHistory({path, pageContext});
  handleExtra({path, pageContext});

  const rjsfProps = {
    widgets: {
      'admin-select-user-widget': AdminSelectUserWidget,
      'admin-select-product-widget': AdminSelectProductWidget,
      'admin-select-article-widget': AdminSelectArticleWidget,
      'admin-date-time-widget': AdminDateTimeWidget,
    },
  };

  return (
    <AdminResource
      {...props}
      rjsfProps={rjsfProps}
      pageContext={pageContext}
      renderCustomAdminCol={renderCustomAdminCol}
      renderCustomAdminSection={renderCustomAdminSection}
      restructureDocuments={async (collection, jsStorageResult) => {
        if (collection === 'user_profile') {
          const {results: _profiles} = jsStorageResult;
          const _private_profiles = await JStorage.fetchAllDocuments(
            'private_profile',
            {
              owner: {
                $in: _profiles.map((p) => p.owner),
              },
            },
          );

          let nextProfiles = [];
          for (let record of _profiles) {
            const targetPrivateProfile = _private_profiles.find(
              (ppr) => ppr.owner === record.owner,
            );
            record = {
              ...record,
              email: targetPrivateProfile?.email || record?.email || '',
              points: targetPrivateProfile?.points || 0,
              provider:
                targetPrivateProfile?.provider || record?.provider || '',
            };
            nextProfiles = [...nextProfiles, record];
          }
          jsStorageResult.results = nextProfiles;
        } else if (collection === 'checkout' && jsStorageResult.length !== 0) {
          return OrderUtil.mergeCheckoutJStorageRespWithOrder(jsStorageResult);
        } else if (
          collection === 'stock_record' &&
          jsStorageResult.length !== 0
        ) {
          const {results: stock_records} = jsStorageResult;
          const products = await JStorage.fetchAllDocuments('product', {
            _id: {
              $in: stock_records.map((stock_record) => ({
                $oid: stock_record.product_id,
              })),
            },
          });

          let nextStockRecords = [];
          for (let record of stock_records) {
            const product = products.find(
              (product) => product.id === record.product_id,
            );
            record = {
              ...record,
              name: product?.name,
            };
            nextStockRecords = [...nextStockRecords, record];
          }
          jsStorageResult.results = nextStockRecords;
        }

        return jsStorageResult;
      }}
      modifyJStorageArgs={{
        modifyFetchDocumentsArgs: (...args) => {
          const [collection, query, ...restArgs] = args;
          let nextQuery = query;

          if (collection === 'order' && !!query.__order_type) {
            if (query.__order_type === 'normal') {
              nextQuery = {
                ...nextQuery,
                is_custom: false,
                payment_subtype: {
                  $nin: [Cart.PAYMENT_SUBTYPE.offline],
                },
              };
            } else if (query.__order_type === 'offline') {
              nextQuery = {
                ...nextQuery,
                payment_subtype: Cart.PAYMENT_SUBTYPE.offline,
              };
            } else if (query.__order_type === 'custom') {
              nextQuery = {
                ...nextQuery,
                is_custom: true,
              };
            }

            delete nextQuery.__order_type;
          }

          if (collection === 'product' && !!query.__stock_amount) {
            nextQuery = {
              ...nextQuery,
              $and: [
                {stock_type: {$ne: 'always'}},
                {stock_amount: {$lte: query.__stock_amount}},
              ],
            };

            delete nextQuery.__stock_amount;
          }

          return [collection, nextQuery, ...restArgs];
        },
      }}
    />
  );
}

export default withPageEntry(AdminResourcePage);
