import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {useOutlet} from 'reconnect.js';
import {showAdminSearchProductModal} from '../../Components/AdminSearchProductModal/index.js';

export default function AdminSelectProductWidget(props) {
  const [products] = useOutlet('products');
  const [selectedProduct, setSelectedProduct] = React.useState(null);

  //rjsf write "readOnly", but props get "readonly".
  const readonly = props.readonly === true || props.readOnly === true;

  React.useEffect(() => {
    if (props.value) {
      setSelectedProduct(
        products.find((product) => product.id === props.value),
      );
    }
  }, [props.value, products]);

  return (
    <Wrapper>
      <div>
        {!props.value ? (
          '尚未設定商品'
        ) : !selectedProduct ? (
          props.value
        ) : (
          <Button
            type="link"
            target="_blank"
            title="點選開啟新分頁編輯"
            href={`/admin/products/?action=detail&id=${selectedProduct.id}`}>{`【 ${
            selectedProduct.name
          }${
            selectedProduct.session
              ? `${selectedProduct.session.date} ${selectedProduct.session.start_time}-${selectedProduct.session.end_time}`
              : ``
          } / $${selectedProduct.price || 0} 】`}</Button>
        )}
      </div>
      {!readonly && (
        <>
          <Button
            onClick={() => {
              props.onChange('');
              setSelectedProduct(null);
            }}
            style={{marginRight: 10}}>
            清除
          </Button>
          <Button
            type="primary"
            onClick={() =>
              showAdminSearchProductModal({
                onChange: (id) => {
                  props.onChange(id);
                },
              })
            }>
            搜尋
          </Button>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  border: 1px solid #d9d9d9;
  padding: 15px;

  & > div {
    margin-right: 15px;
    letter-space: 1.5;
  }
`;
