import React from 'react';
import * as Ant from 'antd';
import Config from '../../../data.json';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import * as AppActions from '../../AppActions';
import {getOutlet} from 'reconnect.js';

export default function MktGetProjectEditLinkButton(props) {
  const [url, setUrl] = React.useState(null);
  const {record} = props;

  return (
    <>
      <Ant.Button
        onClick={async () => {
          AppActions.setLoading(true);
          try {
            const {refresh} = await ApiUtil.req(
              `${Config.apiHost}/mkt/project-access?name=${record.name}&token=${
                getOutlet('user').getValue().token
              }`,
            );
            setUrl(
              `${Config.webHost}/edit-project/?name=${record.name}&refresh=${refresh}`,
            );
          } catch (ex) {
            console.warn(ex);
          }
          AppActions.setLoading(false);
        }}>
        GENERATE
      </Ant.Button>
      {url && (
        <Ant.Button type="link" href={url} target="_blank">
          LINK
        </Ant.Button>
      )}
    </>
  );
}
