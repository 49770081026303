import React from 'react';
import {Button, Collapse, Input, message} from 'antd';
import {useOutlet} from 'reconnect.js';
import {Open} from '@styled-icons/ionicons-outline/Open';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import OrderField from 'rev.sdk.js/Components/OrderField';
import CartList from 'rev.sdk.js/Components/CartList';
import LogisticsInformation from 'rev.sdk.js/Components/LogisticsInformation';
import InvoiceInformation from 'rev.sdk.js/Components/InvoiceInformation';
import * as AppActions from '../../../AppActions';
import ActionButtonSection from './ActionButtonSection';
import UpdateOrderSection from './UpdateOrderSection';
import {adminRenderExtraLogisticsInfo} from '../../../Utils/CustomCheckout';

const {Panel} = Collapse;
const L = AppActions.L;

function CustomAdminOrderDetailForm(props) {
  const {context} = props;
  const {instance, values, setValues} = context;
  const [actions] = useOutlet('actions');

  const openCustomOrderOwnerInfo = async (ownerId) => {
    try {
      actions.setLoading(true);
      console.log('ownerId >', ownerId);
      let user = await JStorage.fetchOneDocument('user_profile', {
        owner: ownerId,
      });
      window.open(`/admin/users/?action=detail&id=${user.id}`);
    } catch (err) {
      console.log(err);
      message.warn('無法取得此客戶資訊');
    } finally {
      actions.setLoading(false);
    }
  };

  return (
    <div style={{margin: '5px 0'}} id="rev-AdminOrderDetailForm">
      {values.payment_subtype === Cart.PAYMENT_SUBTYPE.offline && (
        <>
          <OrderField name="匯款戶名" value={instance.offline_username} />
          <OrderField name="匯款後五碼" value={instance.offline_tx} />
          <OrderField name="匯款時間" value={instance.offline_date} />
        </>
      )}

      <ActionButtonSection {...props} />

      {instance.is_custom ? (
        <div style={{marginTop: 10}}>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <OrderField name="購買人姓名" value={instance.buyer_name} />
            <OrderField name="購買人電話" value={instance.buyer_phone} />
            <OrderField name="購買人信箱" value={instance.buyer_email} />
            <div
              style={{display: 'flex', alignItems: 'center', marginBottom: 5}}>
              <label>客戶 id</label>
              <Button
                type="link"
                icon={<Open size={24} />}
                onClick={() => openCustomOrderOwnerInfo(instance.owner)}
                style={{marginLeft: 5}}
              />
            </div>
            <Input disabled value={instance.owner} />
          </div>
        </div>
      ) : (
        <Collapse defaultActiveKey={[0, 1, 2, 3, 4, 5]}>
          <Panel header="訂單內容" key={0} className="order-info">
            <CartList cartItems={instance.items} disabled={true} />
          </Panel>

          <Panel header={L('buyer_info')} key={1} className="buyer-info">
            <OrderField name="姓名" value={instance.buyer_name} />
            <OrderField name="行動電話" value={instance.buyer_phone} />
            <OrderField name="電子信箱" value={instance.buyer_email} />
            {instance.logistics_type !== 'OVERSEA' && (
              <OrderField
                name="地址"
                value={`${instance.buyer_zip} ${instance.buyer_city} ${instance.buyer_district} ${instance.buyer_address}`}
              />
            )}
            <OrderField
              name="市話"
              value={`${instance.buyer_tel} 分機 ${instance.buyer_tel_ext}`}
            />
          </Panel>

          {instance.logistics_type !== 'OVERSEA' && (
            <Panel
              header={L('receiver_info')}
              key={2}
              className="receiver-info">
              <OrderField name="姓名" value={instance.receiver_name} />
              <OrderField name="行動電話" value={instance.receiver_phone} />
              <OrderField name="電子信箱" value={instance.receiver_email} />
              <OrderField
                name="地址"
                value={`${instance.receiver_zip} ${instance.receiver_city} ${instance.receiver_district} ${instance.receiver_address}`}
              />
              <OrderField
                name="市話"
                value={`${instance.receiver_tel} 分機 ${instance.receiver_tel_ext}`}
              />
            </Panel>
          )}

          <Panel header="物流資訊" key={3} className="logistics-info">
            <LogisticsInformation
              order={instance}
              renderExtraLogisticsInfo={adminRenderExtraLogisticsInfo}
            />
          </Panel>

          <Panel header={L('invoice_info')} key={4} className="invoice-info">
            <InvoiceInformation order={instance} />
          </Panel>

          {instance.logistics_type !== 'OVERSEA' && (
            <Panel
              className="update-info"
              header={
                <h4 style={{color: 'var(--primaryColor)'}}>更新訂單資訊</h4>
              }>
              <UpdateOrderSection {...props} />
            </Panel>
          )}
        </Collapse>
      )}
    </div>
  );
}

export default CustomAdminOrderDetailForm;
